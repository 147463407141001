import { Component, Input, OnInit } from '@angular/core';
import { MeetingSchedulingData } from '../../../../shared/models/meetings.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-meetings-new',
  templateUrl: './meetings-new.component.html',
  styleUrl: './meetings-new.component.scss'
})
export class MeetingsNewComponent {

  @Input() schedulingData!: MeetingSchedulingData;
  protected selectedAgenda: SafeResourceUrl | null = null;

  public states = {
    selectedTab: 'default'
  };

  constructor(private _sanitizer: DomSanitizer) {}


  public selectAgenda(agenda: string): void {
    this.selectedAgenda =  this._sanitizer.bypassSecurityTrustResourceUrl(agenda); 
  }

  public removeSelectedAgenda(): void {
    this.selectedAgenda = null;
  }
}
