import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

@Pipe({ name: 'fromnow' })
export class DateFromNowPipePipw implements PipeTransform {
  transform(timestamp: number | Date | string): string {
    if (typeof timestamp === 'string' || timestamp instanceof Date) {
      return moment.utc(timestamp).local().fromNow();
    }
    return moment.utc(timestamp * 1000).local().fromNow();
  }
}

@Pipe({ name: 'fromnowlimited', standalone: true })
export class DateFromNowLimitedPipe implements PipeTransform {
  transform(timestamp: number | string | Date): string {
    const now = moment().local();

    if (typeof timestamp === 'string' || timestamp instanceof Date) {
      const time = moment.utc(timestamp).local();
      if (now.diff(time, 'days') <= 2) {
        return time.local().fromNow();
      } else {
        return time.local().format('DD/MM - HH:mm');
      }
    }
    

    const time = moment(timestamp * 1000);
    if (now.diff(time, 'days') <= 2) {
      return time.fromNow();
    } else {
      return time.format('DD/MM - HH:mm');
    }
  }
}

@Pipe({ name: 'datetime', standalone: true })
export class DateTimePipe implements PipeTransform {
  transform(timestamp: number | string | Date): string {
    if (typeof timestamp === 'string' || timestamp instanceof Date) {
      return moment.utc(timestamp).local().format('DD/MM/YYYY HH:mm');
    }

    return moment(timestamp * 1000).format('DD/MM/YYYY HH:mm');
  }
}

@Pipe({ name: 'date', standalone: true })
export class DatePipe implements PipeTransform {
  transform(timestamp: number | string | Date): string {
    if (typeof timestamp === 'string' || timestamp instanceof Date) {
      return moment.utc(timestamp).local().format('DD/MM/YYYY');
    }

    return moment(timestamp * 1000).format('DD/MM/YYYY');
  }
}

@Pipe({ name: 'duration', standalone: true })
export class DurationPipe implements PipeTransform {
  transform(duration: number): string {
    return moment.duration(duration, 'seconds').humanize();
  }
}
