import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './components/app/app.component';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EmailSignatureModule } from '../email-signature/email-signature.module';
import { ToastrModule } from 'ngx-toastr';
import { MeetingsModule } from '../meetings/meetings.module';
import { HomeModule } from '../home/home.module';
import { AuthenticationModule } from '../authentication/authentication.module';
import { JwtModule } from '@auth0/angular-jwt';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterOutlet, 
    HeaderComponent, 
    CommonModule, 
    RouterLink, 
    RouterLinkActive,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,

    ToastrModule.forRoot({
      enableHtml: true,
      positionClass: 'toast-top-center',
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          const info = localStorage.getItem('token');
          return info ? JSON.parse(info).access_token : null;
        },
        skipWhenExpired: false,
        allowedDomains: [
          'localhost:59944',
          'localhost:56344',
          'localhost:7071',
          'brnibo.blob.core.windows.net',
          'passport.nibo.com.br',
          'data.nibo.com.br'
        ],
        disallowedRoutes: [
          /test-files.nibo.com.br\/download\/.*/, 
          /arquivos.nibo.com.br\/download\/.*/,
          /brnibo.blob.core.windows.net\/.*/
        ],
      },
    }),

    AuthenticationModule,

    // Todo: Lazy load these modules
    EmailSignatureModule,
    MeetingsModule,
    HomeModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
