import { Directive, HostListener, Input, Output, EventEmitter, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[editMode]'
})
export class EditInPlaceDirective {
  @Input('editMode') editMode!: boolean;
  @Output() editComplete = new EventEmitter<void>();
  private originalText: string = ''; // Propriedade para armazenar o texto original

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(this.el.nativeElement, 'edit-in-place');
    this.renderer.addClass(this.el.nativeElement, 'edit-in-place-view');

    // Adiciona um ícone de edição do lado direito do elemento usando font-awesome, não por dentro
    const icon = this.renderer.createElement('i');
    this.renderer.addClass(icon, 'fa-solid');
    this.renderer.addClass(icon, 'fa-pen-to-square');
    this.renderer.addClass(icon, 'edit-in-place-icon');
    this.el.nativeElement.after(icon);
  }

  private _enableEdit(): void {
    this.originalText = this.el.nativeElement.innerText; // Salva o texto original
    this.el.nativeElement.contentEditable = 'true';
    this.el.nativeElement.focus();
    this.renderer.addClass(this.el.nativeElement, 'edit-in-place-edit'); // Adiciona classe de edição
    this.renderer.removeClass(this.el.nativeElement, 'edit-in-place-view'); // Remove classe de visualização
  }

  private _disableEdit(save: boolean = true): void {
    this.el.nativeElement.contentEditable = 'false';
    if (save) {
      this.editComplete.emit();
    } else {
      this.el.nativeElement.innerText = this.originalText; // Restaura o texto original
    }
    this.renderer.addClass(this.el.nativeElement, 'edit-in-place-view'); // Adiciona classe de visualização
    this.renderer.removeClass(this.el.nativeElement, 'edit-in-place-edit'); // Remove classe de edição
  }

  @HostListener('click') onClick() {
    if (this.editMode) {
      this._enableEdit();
    }
  }

  @HostListener('blur') onBlur() {
    if (this.editMode) {
      this._disableEdit();
    }
  }

  @HostListener('keydown.enter', ['$event']) onEnter(event: KeyboardEvent) {
    if (this.editMode) {
      this._disableEdit();
      event.preventDefault();
    }
  }

  @HostListener('keydown.escape', ['$event']) onEscape(event: KeyboardEvent) {
    if (this.editMode) {
      this._disableEdit(false); // Restaura o texto original
      event.preventDefault();
    }
  }
}
