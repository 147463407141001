import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
  standalone: true
})
export class PhonePipe implements PipeTransform {

  transform(number: string|number): string {
    if (number) {
      const value = number.toString().replace(/\D/g, '');

      let result = '';

      if (value.length > 12) {
        result = value.replace(/(\d{2})?(\d{2})?(\d{5})?(\d{4})/, '+$1 ($2) $3-$4');

      } else if (value.length > 11) {
        result = value.replace(/(\d{2})?(\d{2})?(\d{4})?(\d{4})/, '+$1 ($2) $3-$4');

      } else if (value.length > 10) {
        result = value.replace(/(\d{2})?(\d{5})?(\d{4})/, '($1) $2-$3');

      } else if (value.length > 9) {
        result = value.replace(/(\d{2})?(\d{4})?(\d{4})/, '($1) $2-$3');

      } else if (value.length > 5) {
        result = value.replace(/^(\d{2})?(\d{4})?(\d{0,4})/, '($1) $2-$3');

      } else if (value.length > 1) {
        result = value.replace(/^(\d{2})?(\d{0,5})/, '($1) $2');
      } else {
        if (number !== '') { result = value.replace(/^(\d*)/, '($1'); }
      }
      return result;
    }
    return number.toString();
  }
}

@Pipe({
  name: 'phonedigits'
})
export class PhoneOnlyDigitsPipe implements PipeTransform {
  transform(value: string): string {
    const phonePipe = new PhonePipe();
    const phone = phonePipe.transform(value);
    return 55 + phone.replace(/\D/g, '');
  }
}
