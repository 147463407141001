export const environment = {
  production: false,
  passportUrl: 'https://test-passport.nibo.com.br',
  clientId: '6D3044D7-2C77-4D15-A443-6ED83614F2EE',
  clientSecret: '17D86F54AE704C79866AA81DC991A66A5281DCA9C2D2496CAA',
  salesAPI: 'https://br-nibo-hubspot-ops-staging.azurewebsites.net/api',
  salesAPIToken: 'Y27JtsrXHIDg-tnS4xsPCPMFaa7ZdXGZ1VCQ724mP_GRAzFuLBg7Rg==',
  azureBlobAccountName: 'brniboemailsignature',
  azureBlobSasToken: 'sv=2018-03-28&ss=b&srt=co&sp=rwdlac&se=2100-01-03T04:43:22Z&st=2019-01-02T20:43:22Z&spr=https,http&sig=O55IixT4pPE9rX5Dw8N3aJyGS2VYt%2BHxQxHaLvdxWkk%3D',
  WhapiAPI: 'https://gate.whapi.cloud',
  NiboWhatsappAPI: 'https://test-whatsapp-vendas.nibo.com.br/api/whatsapp',
  NiboWhatsappAPIToken: 'pMuonTgAxHhnhZ2uDLi_M4bw0LbalAUX9tEJx6AdjzQOAzFuu1c2BA==',
  NiboWhatsappSignalR: 'https://test-whatsapp-vendas.nibo.com.br/api/whatsapp',
  NiboWhatsappSignalR2: 'https://test-whatsapp-api.nibo.com.br',
  filesAPIUrl: 'https://test-files.nibo.com.br',
  userProfilePlaceholder: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA9BAMAAAADjhfkAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAAAYUExURf///////////////97e3uDg4OLi4v///48N4JIAAAAEdFJOUwACAwScVyUuAAAAzElEQVQ4y92UwQ3CMAxFo5IRGKAHBuDAACGy1BFYgBGQvD5BIlG+7cYg5QL/1jx9y3HqH8Jv6EJF6w6M9JZJj9R0HnhNP1DNkVIamqVdUrQrM9o17e2Gubc7uB3dmO+q+lJPNi6qH1nVflF+yOoOJhunGfiwg7McCmKSGO6tMYFZY9r01M0HsfC4NWZrqrHvu+8dcaXNjs8tcYaZt9q1epqCo43DZ3gx8RX/c4nTpCVyNtTbbycdvGxxksnLNScVIVPX7xO56DTK8//SEyJPG5zrj0bEAAAAAElFTkSuQmCC',
};
