<div class="meeting-profile" *ngIf="schedulingData">
  <div class="card shadow-sm border-0">
    <div class="card-header meeting-profile-header py-4 text-end border-0">
      <a routerLink="/reunioes" title="Trocar de contato" class="btn btn-sm bg-white bg-opacity-75 mt-n3"><small>Trocar <i class="fas fa-sign-out-alt"></i></small></a>
    </div>
    <div class="card-body bg-light">
      <div class="text-center">
        <div class="mt-n5">
          <img [src]="'https://avatar.iran.liara.run/public'" width="50" height="50"  class="img-fluid rounded-circle border-light border border-3">
        </div>
        <div class="profile-details mt-3">
          <h4>{{schedulingData.contact.name}}</h4>
          <p class="mb-1">{{schedulingData.contact.email}}</p>
          <p class="mt-0 mb-0"><a href="https://app.hubspot.com/contacts/1554114/record/0-1/{{schedulingData.contact.hubspot_id}}" target="_blank" class="btn btn-sm text-orange" title="Abrir este contato"><i class="fa-brands fa-hubspot"></i> Abrir no Hubspot</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
